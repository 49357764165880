export default [
  {
    position: 'Грумер',
    name: 'Сергей Крутов',
    description: 'Грумер уже 4 года. Имеет двух собак: йоркширского терьера и мальтийскую болонку',
    srcImg: '',
  },
  {
    position: 'Грумер',
    name: 'Анна Москалькова',
    description: 'Грумер уже 4 года. Имеет двух собак: йоркширского терьера и мальтийскую болонку',
    srcImg: '',
  },
  {
    position: 'Грумер',
    name: 'Ольга Семёнова',
    description: 'Грумер уже 4 года. Имеет двух собак: йоркширского терьера и мальтийскую болонку',
    srcImg: '',
  },
  {
    position: 'Грумер',
    name: 'Яна Никифорова',
    description: 'Грумер уже 4 года. Имеет двух собак: йоркширского терьера и мальтийскую болонку',
    srcImg: '',
  },
  {
    position: 'Грумер',
    name: 'Оксана Стрельцова',
    description: 'Грумер уже 4 года. Имеет двух собак: йоркширского терьера и мальтийскую болонку',
    srcImg: '',
  },
  {
    position: 'Грумер',
    name: 'Айнур Мерзадинова',
    description: 'Грумер уже 4 года. Имеет двух собак: йоркширского терьера и мальтийскую болонку',
    srcImg: '',
  },
];
