import React from 'react';
import StandardPage from '../components/page/page-standard';
import '../components/groomers-page/groomers-page.scss';
import items from '../__mocks__/groomers';
import Card from '../components/groomer-card/groomer-card';

const pageName = 'groomers-page';

const GroomersPage = () => (
  <StandardPage pageName={pageName} title="Грумеры">

    <div className="groomers-page__subtitle page__title page__title_h2">
      В нашей команде только профессионалы, которые любят животных. А значит относятся к вашим питомцам так же, как к
      своим.
    </div>

    <ul className="groomers-page__list">
      {items.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="groomers-page__item" key={key}>
          <a href="/" className="groomers-page__item-link">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Card className="groomers-page__card" {...item} />
          </a>
        </li>
      ))}
    </ul>
  </StandardPage>
);

export default GroomersPage;
