import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import Image from './image';
import './groomer-card.scss';

const Card = (props) => {
  const {
    className, name, position, description,
  } = props;

  const ROOT_CLASS = 'groomer-card';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <div className={rootClass}>
      <div className="groomer-card__image-wrap"><Image className="groomer-card__image" /></div>
      {name && <div className="groomer-card__position">{position}</div>}
      {position && <h2 className="groomer-card__title page__title page__title_h2">{name}</h2>}
      {description && <div className="groomer-card__description">{description}</div>}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
};

Card.defaultProps = {
  className: '',
  name: '',
  position: '',
  description: '',
};

export default Card;
